import { MAIN_CONFIG } from 'config'
import {
  CsvExport, ExportState, ObjectResults, Option, Sort,
} from 'services/DidymeServices/types'

const EMPTY_RESULTS = { perfectMatchCount: 0, types: [] }
const EMPTY_OBJECTS: ObjectResults = {
  columns: [],
  rows: {
    count: 0, next: null, prev: null, results: [],
  },
}

const EMPTY_SORT: Sort = {
  joinPath: [], kind: undefined,
}

const EMPTY_EXPORT: CsvExport = {
  id: 0,
  creation_date: '',
  url: null,
  task_status: ExportState.failure,
}

const EMPTY_SEARCH_TREE = {
  type: '',
  schema: '',
  description: '',
  matchedField: null,
  count: 0,
  fields: [],
  join: [],
  uniqueId: '',
}

const EMPTY_ESTIMATION = {
  estimated_time: 0,
  warning: null,
}

const findDefaultSchema = () => [MAIN_CONFIG.schemas.find((elem: Option) => MAIN_CONFIG.app_name
  .toLowerCase().includes(elem.label) || MAIN_CONFIG.app_name
  .toLowerCase().includes(elem.value))?.value || MAIN_CONFIG.schemas[0].value]

export {
  EMPTY_RESULTS, EMPTY_OBJECTS, EMPTY_SORT, EMPTY_EXPORT, EMPTY_SEARCH_TREE, EMPTY_ESTIMATION, findDefaultSchema,

}
