/* eslint-disable import/no-unresolved */
import { isLocalhost } from 'serviceWorker'
import { SUBDOMAIN } from './config'

function importConfigs() {
  const r = require.context('./', false, /\.(json)$/)
  return r.keys().reduce((configs, fileName) => {
    const configName = `${fileName.replace(/^.*[\\/]/, '').split('.')[0].toUpperCase()}_CONFIG`
    configs[configName] = r(fileName)
    return configs
  }, {})
}

const CONFIGS = importConfigs()

function getMainConfig() {
  let config = 'DEBUG_CONFIG'
  if (isLocalhost) {
    const { searchParams } = new URL(window.location.href)
    config = searchParams.get('config') ?? config
    return CONFIGS[config]
  }
  let MAIN_CONFIG = CONFIGS[config]
  Object.keys(CONFIGS).forEach(key => {
    const value = CONFIGS[key]
    if (typeof value === 'object' && value !== null) {
      if (value.path === SUBDOMAIN) {
        MAIN_CONFIG = value
      }
    }
  })
  return MAIN_CONFIG
}

const MAIN_CONFIG = getMainConfig()

export { CONFIGS, MAIN_CONFIG }
