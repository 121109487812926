import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import DidymeServices from 'services/DidymeServices'
import { SavedQuery } from 'services/DidymeServices/types'

const EMPTY_QUERY = {
  id: 0,
  name: '',
}
interface DidymeState {
  showHistory: boolean;
  showDeletion: boolean;
  showSave: boolean;
  currentQuery: {id: number, name: string};
  queryLoading: boolean;
  queryList: SavedQuery[];
  conflictQuery: {id: number, name: string};
  updatingQuery: boolean;
}

const initialState: DidymeState = {
  showHistory: false,
  showDeletion: false,
  showSave: false,
  currentQuery: EMPTY_QUERY,
  queryLoading: false,
  queryList: [],
  conflictQuery: EMPTY_QUERY,
  updatingQuery: false,
}

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    toggleHistory: state => {
      state.showHistory = !state.showHistory
    },
    setDeletion: (state, action: PayloadAction<boolean>) => {
      state.showDeletion = action.payload
    },
    toggleSave: state => {
      state.showSave = !state.showSave
    },
    resetQuery: state => {
      state.currentQuery = EMPTY_QUERY
    },
    setQuery: (state, action: PayloadAction<{id: number, name: string}>) => {
      state.currentQuery = action.payload
    },
    resetConflict: state => {
      state.conflictQuery = EMPTY_QUERY
    },
  },
  extraReducers: builder => {
    builder.addCase(DidymeServices.getQueries.pending, state => {
      state.queryLoading = true
    })
    builder.addCase(DidymeServices.getQueries.rejected, state => {
      state.queryLoading = false
    })
    builder.addCase(DidymeServices.getQueries.fulfilled, (state, { payload }) => {
      state.queryLoading = false
      state.queryList = payload
    })
    builder.addCase(DidymeServices.deleteQuery.fulfilled, (state, action) => {
      state.queryList = state.queryList.flatMap(query => (query.id === action.meta.arg ? [] : query))
      if (state.currentQuery.id === action.meta.arg) {
        state.currentQuery = EMPTY_QUERY
      }
      state.updatingQuery = false
      state.showDeletion = false
    })
    builder.addCase(DidymeServices.saveQuery.rejected, (state, action) => {
      state.updatingQuery = false
      const error = action.payload as {data: {name: {message: string, 'query_id': string}}}
      if (error.data?.name?.query_id) {
        state.conflictQuery = { id: +error.data.name.query_id, name: action.meta.arg.name }
      }
    })
    builder.addCase(DidymeServices.saveQuery.fulfilled, (state, { payload }) => {
      state.conflictQuery = EMPTY_QUERY
      state.currentQuery = { id: payload.id, name: payload.name }
      state.showSave = false
      state.updatingQuery = false
    })
    builder.addCase(DidymeServices.updateQuery.fulfilled, (state, { payload }) => {
      state.conflictQuery = EMPTY_QUERY
      state.currentQuery = { id: payload.id, name: payload.name }
      state.queryList = state.queryList.map(query => (query.id === payload.id ? payload : query))
      state.showSave = false
      state.updatingQuery = false
    })
    builder.addCase(DidymeServices.updateQuery.rejected, state => {
      state.updatingQuery = false
    })
    builder.addMatcher(isAnyOf(DidymeServices.saveQuery.pending, DidymeServices.updateQuery.pending,
      DidymeServices.deleteQuery.pending), state => {
      state.updatingQuery = true
    })
  },
})

export const {
  toggleHistory, setDeletion, toggleSave, resetQuery, setQuery, resetConflict,
} = historySlice.actions

export default historySlice.reducer
