import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { Button } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import './SavedQueriesButton.scss'
import { toggleHistory } from 'reducers/history'

export default function SavedQueriesButton(): ReactElement {
  const dispatch = useDispatch()

  const handleOpenModal = () => {
    dispatch(toggleHistory())
  }

  return (
    <div className="queries">
      <Button startIcon={<BookmarkBorderIcon />} onClick={handleOpenModal}>
        {terms.History.savedQueries}
      </Button>
    </div>
  )
}
