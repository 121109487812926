import { PropsWithChildren, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { RootState } from 'Store'

type Props = PropsWithChildren<{
  allowed: string[]
}> & RouteProps

const PrivateRoute = ({ children, allowed = [], ...rest }: Props):ReactElement => {
  const { appPermissions, isLoading } = useSelector((state: RootState) => state.user)

  const isAllowed = allowed.length === 0
  || ((appPermissions as string[]).some(permission => allowed.includes(permission)))

  return (
    <Route
      {...rest}
      render={({ location }) => (isAllowed ? (
        children
      ) : (
        isLoading
          && (
            <Redirect
              to={
                {
                  pathname: '/',
                  state: { from: location },
                }
              }
            />
          )
      ))}
    />
  )
}

export default PrivateRoute
