import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    light: true;
  }
}

export const appTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'not-allowed',
          },
        },
      },
      variants: [
        {
          props: { variant: 'light' },
          style: {
            textTransform: 'none',
            border: '1px solid #EAF1F8',
            background: '#EAF1F8',
            fontWeight: 400,
            color: '#000',
            '&:hover&:not(.Mui-disabled)': {
              border: '1px solid #1775E0',
              background: '#1775E0',
              color: '#FFFFFF',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        },
      ],
    },
  },
})

export default appTheme
