import { lazy, LazyExoticComponent, ReactElement } from 'react'
import userIcon from 'assets/icons/user.svg'
import { MAIN_CONFIG } from 'config'

const Home = lazy(() => import('../Home'))

export const ACCESS_PERM = MAIN_CONFIG.access_perm

export type NavElement = {
  icon: string;
  title: string;
  path: string;
  component: LazyExoticComponent<() => ReactElement>;
  role: Array<string>;
}

const navRoutes: NavElement[] = [
  {
    icon: userIcon,
    title: '',
    path: '',
    component: Home,
    role: [ACCESS_PERM],
  },
]

export default navRoutes
