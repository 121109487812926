/* eslint-disable camelcase */
import {
  ArrayFormat, deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'
import {
  Estimation,
  GraphParameters,
  ObjectParameters, ObjectType, SaveQueryParams, SavedQuery, SearchParameters,
} from './types'
import { buildFetchGraphParameters, buildFetchParameters } from './utils'

const searchObjects = createAsyncThunk(
  'didyme/searchObjects',
  async (params: SearchParameters, thunkApi) => {
    try {
      const from = { from: params.from ? `${params.from}` : {} }
      const response = await get(`/${URI.didyme}/search`, { ...params, ...from }, ArrayFormat.indices)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchExportLink = createAsyncThunk(
  'didyme/fetchExportLink',
  async (id :number, thunkApi) => {
    try {
      const response = await get(`/${URI.didyme}/extractions/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchObjects = createAsyncThunk(
  'didyme/fetchObjects',
  async ({
    params, objectDate, page, sortedField, filterList, get_csv = false, cursor,
  } : ObjectParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchParameters({
        params, objectDate, page, sortedField, filterList,
      })
      const url = page !== 1 && cursor ? cursor : `/${URI.didyme}/gaia-fetch/`
      const pageExport = get_csv ? { get_csv } : {}
      const response = await post(url, fetchParameters,
        { params: { ...pageExport } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const findShortestPath = createAsyncThunk(
  'didyme/findShortestPath',
  // todo refacto
  async ({
    fromType, toType, via = '', schemas, isNewObject = false,
  }: {fromType: ObjectType, toType: ObjectType, via?: string, schemas: string[],
    isNewObject?: boolean}, thunkApi) => {
    try {
      const from = `${fromType.schema}:${fromType.type}`
      const to = `${toType.schema}:${toType.type}`
      const by_field = via ? { by_field: via } : {}
      const response = await get(`/${URI.didyme}/paths/${from}/${to}`, { n_paths: 1, schemas, ...by_field })
      return {
        paths: response.paths, fromType, via, toType, isNewObject,
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const preFetchObjects = createAsyncThunk(
  'didyme/preFetchObjects',
  async ({
    params, objectDate, page, sortedField, filterList,
  } : ObjectParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchParameters({
        params, objectDate, page, sortedField, filterList,
      })
      const response: Estimation = await post(`/${URI.didyme}/gaia-fetch-check/`, { ...fetchParameters })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchObjectsGraph = createAsyncThunk(
  'didyme/fetchObjectsGraph',
  async ({
    params, objectDate, filterList,
  } : GraphParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchGraphParameters({
        params, objectDate, filterList,
      })
      const response = await post(`/${URI.didyme}/graph/`, { ...fetchParameters })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const fetchExploratoryGraph = createAsyncThunk(
  'didyme/fetchExploratoryGraph',
  async ({
    params, objectDate, filterList, currentSchema,
  } : GraphParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchGraphParameters({
        params, objectDate, filterList,
      })
      const response = await post(`/${URI.didyme}/graph/`, { ...fetchParameters }, {
        params: {
          schema: currentSchema,
        },
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const saveQuery = createAsyncThunk(
  'didyme/saveQuery',
  async (params: SaveQueryParams, thunkApi) => {
    try {
      const {
        searchDate, sortedField, filterList, tree, name, origin,
      } = params
      const fetchParameters = buildFetchParameters({
        params: tree, objectDate: searchDate, page: 1, sortedField, filterList,
      })
      const response = await post(`/${URI.didyme}/queries/`, { name, payload: fetchParameters, origin })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const updateQuery = createAsyncThunk(
  'didyme/updateQuery',
  async (params: SaveQueryParams, thunkApi) => {
    try {
      const {
        id, searchDate, sortedField, filterList, tree, name, origin,
      } = params
      const fetchParameters = buildFetchParameters({
        params: tree, objectDate: searchDate, page: 1, sortedField, filterList,
      })
      const response = await patch(`/${URI.didyme}/queries/${id}`, { name, payload: fetchParameters, origin })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getQueries = createAsyncThunk(
  'didyme/getQueries',
  async (origin: string, thunkApi) => {
    try {
      const response = await get(`/${URI.didyme}/queries/`, { origin })
      return response.results as SavedQuery[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteQuery = createAsyncThunk(
  'didyme/deleteQuery',
  async (id: number, thunkApi) => {
    try {
      const response = await deleteRequest(`/${URI.didyme}/queries/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const DidymeServices = {
  searchObjects,
  fetchObjects,
  fetchExportLink,
  findShortestPath,
  preFetchObjects,
  fetchObjectsGraph,
  fetchExploratoryGraph,
  saveQuery,
  updateQuery,
  getQueries,
  deleteQuery,
}

export default DidymeServices
