/* eslint-disable max-len */
const terms = {
  Common: {
    loading: 'Chargement',
    create: 'Créer',
    pendingQuery: 'Requête en cours...',
    Error: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application',
      appSubtitle: 'Veuillez contacter votre administrateur',
      exitButton: 'Retour au portail des applications',
      server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
      unknown: 'Une erreur est survenue.',
      notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
      badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
    },
    searching: 'Recherche...',
    false: 'Non',
    true: 'Oui',
    via: 'via',
  },
  Error: {
    server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
    unknown: 'Une erreur est survenue.',
    notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
    badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
    conflict: 'Cet objet est en conflit. Réglez le conflit et réessayez.',
    forbidden: {
      appTitle: "Vous n'avez pas les droits nécessaires pour accéder à cette application",
      appSubtitle: 'Veuillez contacter votre administrateur',
      content: "Vous n'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.",
      exitButton: 'Retour au portail des applications',
    },
  },
  Search: {
    subtitle: 'Visualiser et extraire des données Gaia',
    subtitleOther: 'Visualiser et extraire des données',
    searchObject: 'Rechercher un objet dans la base de données Gaia',
    searchObjectOther: 'Rechercher un objet dans les bases de données',
    object: 'Objet',
    objects: 'Objets',
    result: 'occurence exacte',
    results: 'occurences exactes',
    noResults: 'Pas d\'occurences exactes',
    empty: 'Vider',
    search: 'Rechercher',
    newSearch: 'Faire une nouvelle recherche',
    addedColumn: 'Colonne ajoutée pour contextualiser l\'id',
    noObjects: 'Pas de résultats',
    list: 'liste',
    filters: 'Filtres',
    addFilter: 'Ajouter un filtre',
    column: 'Colonne',
    operator: 'Opérateur',
    value: 'Valeur',
    contains: 'contient',
    notContains: 'ne contient pas',
    equals: 'est égal à',
    notEquals: 'n\'est pas égal à',
    startsWith: 'commence par',
    endsWith: 'finit par',
    greaterThan: 'supérieur ou égal à',
    lowerThan: 'inférieur ou égal à',
    strictGreaterThan: 'supérieur à',
    strictLowerThan: 'inférieur à',
    notNull: 'n\'est pas vide',
    null: 'est vide',
    nextPage: 'Page suivante',
    previousPage: 'Page précédente',
    reset: 'Réinitialiser la requête',
    Tree: {
      completeness: 'Complétude :',
      distance: 'Distance :',
      attributes: "Attributs de l'objet",
      linkedObjects: 'Objets liés',
      children: 'Objets enfants',
      addedAttributes: 'Attributs ajoutés automatiquement',
    },
    Estimation: {
      time: 'Temps d\'export estimé :',
      reduceTime: 'Vous pouvez affiner la recherche pour réduire ce temps d\'export',
      minutes: 'min',
    },
    rootDelete: 'Vous ne pouvez pas supprimer un élément racine avec plusieurs enfants',
    viewTypes: {
      table: 'Vue Tableau',
      webServices: 'Vue Web Services',
      graph: 'Vue Graph',
      exploreGraph: 'Explorer le graph',
    },
  },
  Graph: {
    horizontalView: 'Vue horizontale',
    verticalView: 'Vue verticale',
  },
  Export: {
    failure: 'Échec de l`import',
  },
  History: {
    savedQueries: 'Requêtes sauvegardées',
    delete: 'Supprimer',
    openQuery: 'Ouvrir la requête',
    deleteQuery: 'Supprimer la requête',
    deleteConfirm: 'Confirmer la suppression de la requête enregistrée',
    deleteButton: 'Confirmer la suppression',
    saveQuery: 'Enregistrer ma requête',
    findQueries: 'Vous pouvez retrouver vos requêtes en cliquant sur "Requêtes sauvegardées"',
    saveMyQuery: 'Sauvegarder la requête',
    queryName: 'Nom de la requête',
    saveSuccess: 'Requête enregistrée',
    deleteSuccess: 'Requête supprimée',
    conflict: 'Cette requête sera écrasée, pour créer une nouvelle sauvegarde veuillez changer le nom de la requête',
    confirmConflict: 'Écraser et sauvegarder la requête',
    updateSuccess: 'Requête mise à jour',
    edition: 'Vous éditez la requête',
    currentlyEditing: 'Requête en cours d\'édition',
  },
  Schemas: {
    oneRequired: 'Au moins un schéma est requis',
  },
} as const

export default terms
