import { Checkbox, Tooltip, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { blue } from '@mui/material/colors'
import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSchema } from 'reducers/didyme'
import { ObjectSchemas } from 'services/DidymeServices/types'
import terms from 'common/terms'
import { MAIN_CONFIG } from '../../config'
import './GaiaSwitch.scss'

export default function GaiaSwitch(): ReactElement {
  const dispatch = useDispatch()
  const { currentSchema, fetchLoading } = useSelector((state: RootState) => state.didyme)

  const handleSchemaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentSchema.includes(event.target.value as ObjectSchemas)) {
      dispatch(setSchema(currentSchema.flatMap(schema => (schema === event.target.value ? [] : schema))))
      return
    }
    dispatch(setSchema([...currentSchema, event.target.value as ObjectSchemas]))
  }

  return (
    <div className={`switch-wrapper${fetchLoading ? ' disabled' : ''}`}>
      <FormControl>
        {MAIN_CONFIG.schemas.map((s: any) => (
          <Tooltip
            key={s.label}
            disableHoverListener={!(currentSchema?.includes(s.value) && currentSchema.length === 1)}
            title={terms.Schemas.oneRequired}
          >
            <FormControlLabel
              value={s.value}
              sx={{
                color: 'white',
                fontWeight: 900,
              }}
              control={(
                <Checkbox
                  checked={currentSchema.includes(s.value)}
                  disabled={currentSchema.includes(s.value) && currentSchema.length === 1}
                  onChange={handleSchemaChange}
                  sx={{
                    color: blue[800],
                    '&.Mui-checked': {
                      color: blue[600],
                    },
                    '&.Mui-disabled': {
                      opacity: 0.3,
                    },
                  }}
                />
)}
              label={<Typography className="font-weight-bold">{s.label}</Typography>}
            />
          </Tooltip>
        ))}
      </FormControl>
    </div>
  )
}
