/* eslint-disable camelcase */
// TODO  better type naming

export type ObjectField = {
  slug: string;
  description: string;
  type: string | LinkedObject;
  unbounded: boolean;
  displayedName: string;
  additional?: boolean;
  ignore: boolean;
}

export type LinkedObject = {
  slug: string;
  schema: string;
}

export type Link = {
  field: string;
  kind: 'forward' | 'reverse'
  completenesse: number;
}

type Path = {
  tree: ObjectType;
  estimatedCompleteness: number;
  distance: number;
  lastJoinField: string;
}

export type Child = {
  completeness: number;
  ratio: number;
  slug: string;
}
export type ObjectType = {
  type: string;
  schema: string;
  description: string;
  matchedField: string | null;
  count: number;
  fields: ObjectField[];
  join: ObjectType[];
  path?: Path;
  tree?: ObjectType;
  link?: Link;
  isIntermediate?: boolean;
  estimatedCompleteness?: number;
  uniqueId: string;
  children?: Child[];
}

export type SearchResults = {
  perfectMatchCount: number;
  types: ObjectType[];
}

export type SearchParameters = {
  q: string;
  from?: string;
  limit?: number;
  schemas?: string[];
}

export enum ObjectSchemas {
  Gaia = 'gaia',
  Ve = 'gaia_ve',
  Basic = 'basic'
}

type ColumnType = 'long' | 'double' | 'int' | 'string' | 'uuid' | 'list' | 'dateTime' | 'boolean'

export const columnNumberTypes = ['long', 'double', 'int', 'list', 'dateTime']

export const columnStringTypes = ['string', 'uuid']

export type Column = {
  displayedName: string;
  joinPath: string[];
  rootJoinPath: string[] | null;
  unbounded: boolean;
  columnType: ColumnType;
}

export type Row = string[]

type Result = {
  count: number;
  next: string | null;
  prev: string | null;
  results: Row[];
}

export type ObjectResults = {
  columns: Column[];
  rows: Result;
}

export type Export = {
  extraction_id: number;
}

export type ObjectParameters = {
  params: ObjectType;
  page: number;
  objectDate: string;
  sortedField: Sort;
  filterList: Filter[];
  get_csv?: boolean;
  cursor?: string;
}

export type Order = 'asc' | 'desc' | undefined

export type Sort = {
  joinPath: Column['joinPath'];
  kind: Order;
}

export enum StringFilterOperator {
  equals = 'eq',
  notEquals = 'neq',
  contains = 'contains',
  notContains = 'ncontains',
  startsWith = 'startswith',
  endsWith = 'endswith',
  notNull = 'notnull',
  null = 'null'
}

export enum NumberDateFilterOperator {
  equals = 'eq',
  notEquals = 'neq',
  greaterThan = 'gte',
  strictGreaterThan = 'gt',
  lowerThan = 'lte',
  strictLowerThan = 'lt',
  notNull = 'notnull',
  null = 'null'
}

export enum BooleanFilterOperator {
  equals = 'eq',
  notEquals = 'neq',
}

export const FilterOperator = { ...StringFilterOperator, ...NumberDateFilterOperator, ...BooleanFilterOperator }

export type FilterOperatorType = StringFilterOperator | NumberDateFilterOperator | BooleanFilterOperator

export type Filter = {
  id: number;
  column: Column;
  operator: FilterOperatorType | string;
  value: string;
  joinPath?: string[]
}

export enum ExportState {
  failure = 'FAILURE',
  pending = 'PENDING',
  success = 'SUCCESS'
}

export type CsvExport = {
  id: number;
  creation_date: string;
  url: string | null;
  task_status: ExportState;
}

export type HiddenField = {
  [key: string]: ObjectField[];
}

export type Estimation = {
  estimated_time: number;
  warning: string | null;
}

export enum ResultView {
  table = 'table',
  webService = 'webService',
  graph = 'graph',
  exploreGraph = 'exploreGraph',
}

export type GraphParameters = {
  params: ObjectType;
  objectDate: string;
  filterList: Filter[];
  currentSchema?: string;
}

export type LastSearch = {
  type: string;
  id: string;
  schema: string;
}

export type SaveQueryParams = {
  id?: number;
  name: string;
  tree: ObjectType;
  searchDate: string;
  sortedField: Sort;
  filterList: Filter[];
  origin: string;
}

export type SavedQuery = {
  name: string;
  payload: { date: string; filters: Filter[], tree: ObjectType};
  ordering: Sort[];
  filters: Filter[];
  origin: string;
  id: number;
  last_saved: string;
}

export type Option = {
  label: string;
  value: string;
}
